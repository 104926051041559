<template>
  <div class="table">
    日期筛选：<el-date-picker
      v-model="value1"
      type="datetimerange"
      range-separator="To"
      start-placeholder="开始时间"
      end-placeholder="结束时间"
      @change="changeTime"
    />
    <el-button
      style="float: right; margin-right: 20px"
      @click="exportExcel(showList)"
      >导出</el-button
    >
    <el-button style="float: right; margin-right: 20px" @click="reflash"
      >搜索</el-button
    >
    <el-table :data="showList" stripe style="width: 100%">
      <el-table-column prop="date" label="日期" />
      <el-table-column prop="status" label="状态" />
      <el-table-column prop="message" label="内容" />
      <el-table-column prop="email" label="邮箱" />
      <el-table-column prop="name" label="姓名" />
      <el-table-column prop="phone" label="手机号" />
      <!-- <el-table-column label="操作">
        <template #default="scope">
          <el-button
            size="small"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column> -->
    </el-table>
  </div>
</template>

<script>
import axios from "axios";
import dayjs from "dayjs";
import * as xlsx from "xlsx";
export default {
  data() {
    return {
      tableData: [],
      value1: null,
      value2: null,
    };
  },
  created() {
    this.loadData();
  },
  computed: {
    showList() {
      if (!this.value2) return this.tableData;
      let res = this.tableData.filter((item) => {
        if (
          item.datejs.isAfter(this.value2[0]) &&
          item.datejs.isBefore(this.value2[1])
        ) {
          return item;
        }
      });
      return res;
    },
  },
  methods: {
    //表头数据切换
    changeTableHead(
      tableData,
      fieldName = {
        date: "日期",
        message: "内容",
        email: "邮箱",
        name: "姓名",
        phone: "手机号",
      }
    ) {
      const list = tableData.map((item) => {
        const obj = {};
        for (const k in item) {
          if (fieldName[k]) {
            obj[fieldName[k]] = item[k];
          }
        }
        return obj;
      });
      return list;
    },
    // handleDelete(index, content) {
    //   this.tableData.forEach((e) => {
    //     if (e == content) {
    //       console.log(e);
    //     }
    //   });
    //   console.log(index, content);
    // },
    reflash() {
      window.location.reload(true);
    },
    changeTime() {
      let res = [];
      this.value2 = null;
      if (!this.value1) return;
      this.value1.forEach((item) => {
        res.push(dayjs(item));
      });
      this.value2 = res;
    },
    loadData() {
      axios
        .request({
          url: "https://ceeg-admin.linktwins.com/list",
          method: "get",
        })
        .then((res) => {
          res.data.forEach((item) => {
            let date = dayjs(new Date(item.date));
            item.date = date.format("YYYY-MM-DD HH:mm:ss");
            item.datejs = date;
          });
          this.tableData = res.data;
        });
    },
    exportExcel(tableData, fileName = "列表", pageName = "Sheet1") {
      const list = this.changeTableHead(tableData);
      // 创建工作表
      const data = xlsx.utils.json_to_sheet(list);
      // 创建工作簿
      const wb = xlsx.utils.book_new();
      // 将工作表放入工作簿中
      xlsx.utils.book_append_sheet(wb, data, pageName);
      // 生成文件并下载
      xlsx.writeFile(wb, `${fileName}.xlsx`);
    },
  },
};
</script>
<style lang="scss" scoped></style>
